import { gql } from '@apollo/client'

const UPDATE_VARIANT_PRICING = gql`
  mutation updateVariantPricing($id: uuid!, $price: numeric!, $vat: numeric!) {
    update_product_variants(
      where: { id: { _eq: $id } }
      _set: { price: $price, vat: $vat, updated_at: "now()" }
    ) {
      affected_rows
    }
  }
`

const UPSERT_CORE_VARIANTS_PRICING = gql`
  mutation upsertCoreVariantsPricing(
    $values: [product_variants_insert_input!]!
  ) {
    insert_product_variants(
      objects: $values
      on_conflict: {
        constraint: product_variants_pkey
        update_columns: [vat, price, updated_at]
      }
    ) {
      affected_rows
    }
  }
`

const UPSERT_VARIANT_PRICING = gql`
  mutation upsertVariantPricing($values: [pricings_insert_input!]!) {
    insert_pricings(
      objects: $values
      on_conflict: {
        constraint: pricings_pkey
        update_columns: [vat, price, price_with_vat, updated_at]
      }
    ) {
      affected_rows
    }
  }
`

const UPSERT_PRICINGS = gql`
  mutation upsertPricing(
    $variants: [product_variants_insert_input!]!
    $fulfilmentTypes: [pricings_insert_input!]!
  ) {
    insert_product_variants(
      objects: $variants
      on_conflict: {
        constraint: product_variants_pkey
        update_columns: [vat, price, price_with_vat, updated_at]
      }
    ) {
      affected_rows
    }

    insert_pricings(
      objects: $fulfilmentTypes
      on_conflict: {
        constraint: pricings_pkey
        update_columns: [vat, price, price_with_vat, published_at, updated_at]
      }
    ) {
      affected_rows
    }
  }
`

const UNPUBLISH_FULFILMENT_TYPE_PRICINGS = gql`
  mutation unpublishFulfilmentTypePricings(
    $fulfilmentTypes: [pricings_insert_input!]!
  ) {
    insert_pricings(
      objects: $fulfilmentTypes
      on_conflict: {
        constraint: pricings_pkey
        update_columns: [published_at, updated_at]
      }
    ) {
      affected_rows
    }
  }
`

const DELETE_FULFILMENT_TYPE_PRICINGS = gql`
  mutation deleteFulfilmentTypePricing($pricingIds: [uuid!]) {
    delete_pricings(where: { id: { _in: $pricingIds } }) {
      affected_rows
    }
  }
`

const GET_MERCHANT_STORES_FLAGS = gql`
  query getStoresFlags($merchantId: uuid!) {
    stores(
      where: {
        merchant_id: { _eq: $merchantId }
        archived_at: { _is_null: true }
      }
    ) {
      id
      slug
      name
      merchant_id
      same_day_pickup: settings(path: "same_day_pickup")
      same_day_delivery: settings(path: "same_day_delivery")
      table_enabled: settings(path: "table_enabled")
      pre_order_settings {
        pickup_enabled
        delivery_enabled
      }
    }
  }
`

export {
  UPDATE_VARIANT_PRICING,
  UPSERT_VARIANT_PRICING,
  GET_MERCHANT_STORES_FLAGS,
  UPSERT_CORE_VARIANTS_PRICING,
  UPSERT_PRICINGS,
  UNPUBLISH_FULFILMENT_TYPE_PRICINGS,
  DELETE_FULFILMENT_TYPE_PRICINGS
}
