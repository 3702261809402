import React, { useEffect, useState } from 'react'
import { Checkbox, Col, Divider, Row } from 'antd'
import { useLazyQuery, useQuery } from '@apollo/client'
import cloneDeep from 'lodash/cloneDeep'
import { useSession } from '@slerp/accounts'
import InventoryCollapsePanels from './InventoryCollapsePanels'
import { countPublished, flattenItems } from '../helpers'
import {
  GET_ALL_STORE_SAMEDAY_INVENTORY,
  GET_ALL_STORE_PREORDER_INVENTORY,
  GET_MERCHANT_CATEGORIES,
  GET_STORE_CATEGORIES_ARRANGEMENTS
} from '../DashboardQueries'

import { GET_PRODUCTS_CATEGORIES } from 'components/ProductsAndModifiers/Products/ProductQueries'
import { ProductCategory } from 'components/ProductsAndModifiers/Products/Form'
import {
  InventoryGroup,
  InventoryItem,
  SortedCategory,
  StoreInventory,
  StoreVariant,
  CategoriesArrangements
} from '../types'
import { Category } from '@slerp/controls'

interface ProductVariantsProps {
  isPreorder: boolean
  storeId: string
  setMasterPristineVariants: (v: InventoryGroup[]) => void
  setMasterVariants: (v: InventoryGroup[]) => void
  sortedGroups: SortedCategory[]
  setSortedGroups: () => void
}

const ProductVariants = ({
  isPreorder,
  storeId,
  sortedGroups,
  setMasterPristineVariants,
  setMasterVariants,
  setSortedGroups
}: ProductVariantsProps) => {
  const { merchant } = useSession()
  const [variants, setVariants] = useState<InventoryGroup[]>([])

  const [categoriesArrangements, setCategoriesArrangements] = useState<
    Array<CategoriesArrangements>
  >([])

  const allVariants = flattenItems(variants)

  const updateVariants = (variantsToUpdate: InventoryItem[]) => {
    const updatedVariants = cloneDeep(variants)
    variantsToUpdate.forEach((variant) => {
      updatedVariants.forEach((v, index) => {
        updatedVariants[index].items = updatedVariants[index].items.map(
          (item) => {
            if (item.id !== variant.id) return item
            return { ...item, isPublished: variant.isPublished }
          }
        )
      })
    })

    setVariants(updatedVariants)
    setMasterVariants(updatedVariants)
  }

  const { data: storeInventoryData, loading: storeInventoryLoading } =
    useQuery<StoreInventory>(
      isPreorder
        ? GET_ALL_STORE_PREORDER_INVENTORY
        : GET_ALL_STORE_SAMEDAY_INVENTORY,
      {
        variables: { storeId },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true
      }
    )

  const { data: categoriesData, loading: categoriesLoading } = useQuery(
    GET_MERCHANT_CATEGORIES,
    {
      variables: { merchantId: merchant.id },
      fetchPolicy: 'no-cache'
    }
  )

  useQuery(GET_STORE_CATEGORIES_ARRANGEMENTS, {
    variables: { storeId, orderType: isPreorder ? 'preorder' : 'sameday' },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setCategoriesArrangements(data.categories_arrangements)
    }
  })
  const [getProducsCategories] = useLazyQuery(GET_PRODUCTS_CATEGORIES, {
    onCompleted: (data: { products_categories: Array<ProductCategory> }) => {
      if (!storeInventoryData) return

      const { products_categories } = data
      const { store_variants } = storeInventoryData

      let storeVariantsForDrawer: InventoryGroup[] = []
      const activeCategories = categoriesData.categories.map(
        (cat: Category) => cat.id
      )

      products_categories
        .filter((pc) => activeCategories.includes(pc.category_id))
        .forEach((pc) => {
          const data = storeVariantsForDrawer.find(
            (d) => d.id === pc.category_id
          )
          const filtered_store_variant = store_variants.filter(
            (sv) => sv.product_variant.product.id == pc.product_id
          )

          if (filtered_store_variant) {
            const items = filtered_store_variant.map((sv) => ({
              label: sv.product_variant.name,
              value: sv.variant_id,
              id: sv.variant_id,
              groupId: pc.category_id,
              isPublished: !!sv.published_at,
              productId: sv.product_variant.product.id,
              productName: sv.product_variant.product.name,
              productAllergens: sv.product_variant.product.allergens,
              productDefaultVariantId:
                sv.product_variant.product.default_variant_id,
              defaultVariant: sv.product_variant.default_variant
            }))
            if (data) {
              const filtered = storeVariantsForDrawer.filter(
                (d) => d.id !== pc.category_id
              )
              storeVariantsForDrawer = [
                ...filtered,
                {
                  ...data,
                  items: [...data.items, ...items]
                }
              ]
            } else {
              storeVariantsForDrawer = [
                ...storeVariantsForDrawer,
                {
                  id: pc.category_id,
                  name: pc.category.name,
                  items: items
                }
              ]
            }
          }
        })

      setVariants(storeVariantsForDrawer)
      setMasterPristineVariants(storeVariantsForDrawer)
      setMasterVariants(storeVariantsForDrawer)
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  })

  const publishedVariantsCount = countPublished(variants)
  const areAllVariantsSelected = publishedVariantsCount === allVariants.length

  useEffect(() => {
    if (!storeInventoryLoading && !categoriesLoading) {
      if (!storeInventoryData) return

      const { store_variants } = storeInventoryData
      const productIds = [
        ...new Set(
          store_variants.map((v: StoreVariant) => v.product_variant.product.id)
        )
      ].map((id) => ({
        product_id: { _eq: id }
      }))

      getProducsCategories({
        variables: {
          productIds
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeInventoryData, categoriesData])

  return (
    <>
      <Row justify='space-between' className='_mb-14'>
        <Col>
          <Checkbox
            checked={areAllVariantsSelected}
            onChange={(e) => {
              const allPublished = allVariants.map((variant) => ({
                ...variant,
                isPublished: e.target.checked
              }))
              updateVariants(allPublished)
            }}
          >
            Select All Products
          </Checkbox>
        </Col>
        <Col>{publishedVariantsCount} selected</Col>
      </Row>
      <InventoryCollapsePanels
        inventoryGroups={variants}
        sortedGroups={sortedGroups}
        categoriesArrangements={categoriesArrangements}
        onChange={updateVariants}
        setSortedGroups={setSortedGroups}
      />
      <Divider className='_mb-0' />
    </>
  )
}

export default ProductVariants
