import { Charge, OrderInfo, Refund } from '../../type'

export const getChargeDescription = (
  charge: Charge,
  order: OrderInfo,
  charges: Charge[] = [],
  refunds: Refund[] = []
) => {
  const applicationFeeAmount =
    charge.transaction_log?.application_fee_amount ||
    charge.order?.transaction_logs?.[0]?.application_fee_amount ||
    0

  const feeAmount = toFixed(applicationFeeAmount)
  const total = toFixed(order.total)
  const amount = toFixed(charge.amount)

  const chargeType = `${charge.charge_type_reason}_${charge.charge_type}`

  switch (chargeType) {
    case `free_order_commission`:
      return `Free Order Commission for ${order.transaction_id}`
    case 'free_order_delivery_fee':
      return `Courier charge for ${order.transaction_id}`
    case 'transaction_shortfall':
      return `Charges shortfall for ${order.transaction_id}; GTV was £${total} and application fee was £${feeAmount}`
    case 'additional_courier_delivery_fee':
      return `Additional courier charge for ${order.transaction_id}`
    case 'rejection_payment':
      return `Card fee (£${amount}) for the rejection of ${order.transaction_id}`
    case 'refund_delivery_fee':
      return `Courier charge (£${amount}) for the full refund of ${order.transaction_id}`
    case 'refund_payment':
      return `Card fee (£${amount}) for the full refund of ${order.transaction_id}`
    case 'refund_shortfall':
      const isPartialRefund = refunds?.length > 1

      const shortfall = charges.find(
        (charge) =>
          charge.charge_type_reason === 'transaction' &&
          charge.charge_type === 'shortfall'
      )?.amount
      const shortfallAmount = shortfall ? parseFloat(shortfall) : 0

      let partnerAmount = order.total - applicationFeeAmount + shortfallAmount

      if (isPartialRefund) {
        refunds
          .filter(
            (refund) =>
              refund.inserted_at < charge.inserted_at &&
              refund.id !== charge.refund?.id
          )
          .forEach((refund) => {
            partnerAmount -= refund.amount
          })
      }

      return `Charges shortfall for the partial refund of ${
        order.transaction_id
      }. Refund amount of £${amount} is higher than the ${
        isPartialRefund ? 'balance ' : ''
      }partner amount of £${toFixed(partnerAmount)} for the transaction.`
    default:
      return ''
  }
}

export const getChargeStatus = (reverted_at: string | null) => {
  return reverted_at ? 'Refunded' : 'Completed'
}

export const toFixed = (value: number | string | null) => {
  switch (typeof value) {
    case 'number':
      return value.toFixed(2)
    case 'string':
      try {
        const parsed = parseFloat(value)
        const fixed = parsed.toFixed(2)
        return fixed
      } catch (e) {
        return value
      }
    default:
      return null
  }
}
